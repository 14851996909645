import {
    collection,
    deleteDoc,
    doc,
    getDocs,
    limit,
    onSnapshot,
    query,
    orderBy,
  
    startAfter,
  } from "firebase/firestore";
  import React, { useState, useEffect } from "react";
  import PosterSection from "../components/PosterSection";
  import Spinner from "../components/Spinner";
  import { db } from "../firebase";
  import { toast } from "react-toastify";
  
 
  
  const HomePoster = ({ setActive, user, active }) => {
    const [loading, setLoading] = useState(true);
    const [poster, setBlogs] = useState([]);
    const [lastVisible, setLastVisible] = useState(null);
    const [hide, setHide] = useState(false);
  
    
  
    useEffect(() => {
      const unsub = onSnapshot(
        collection(db, "poster"),
        (snapshot) => {
          let list = [];
          snapshot.docs.forEach((doc) => {
          
            list.push({ id: doc.id, ...doc.data() });
          });
          setLoading(false);
          setActive("homeposter");
        },
        (error) => {
        }
      );
  
      return () => {
        unsub();
      };
    }, [setActive, active]);
  
    useEffect(() => {
      getBlogs();
      setHide(false);
    }, [active]);
  
    const getBlogs = async () => {
      const blogRef = collection(db, "poster");
      const firstFour = query(blogRef, orderBy("title"), limit(4));
      const docSnapshot = await getDocs(firstFour);
      setBlogs(docSnapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() })));
      setLastVisible(docSnapshot.docs[docSnapshot.docs.length - 1]);
    };
  
  
    const updateState = (docSnapshot) => {
      const isCollectionEmpty = docSnapshot.size === 0;
      if (!isCollectionEmpty) {
        const blogsData = docSnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        setBlogs((poster) => [...poster, ...blogsData]);
        setLastVisible(docSnapshot.docs[docSnapshot.docs.length - 1]);
      } else {
        toast.info("No more Movies to display");
        setHide(true);
      }
    };
  
    const fetchMore = async () => {
      setLoading(true);
      const blogRef = collection(db, "poster");
      const nextFour = query(
        blogRef,
        orderBy("title"),
        limit(4),
        startAfter(lastVisible)
      );
      const docSnapshot = await getDocs(nextFour);
      updateState(docSnapshot);
      setLoading(false);
    };
  
    
    
  
    if (loading) {
      return <Spinner />;
    }
  
    const handleDelete = async (id) => {
      if (window.confirm("Are you sure wanted to delete that Movie ?")) {
        try {
          setLoading(true);
          await deleteDoc(doc(db, "poster", id));
          toast.success("Movie deleted successfully");
          setLoading(false);
        } catch (err) {
        }
      }
    };
  
   
  
  
  
    return (
      <div className="container-fluid pb-4 pt-4 padding">
        <div className="container padding">
          <div className="row mx-0">
            <div className="col-md-8">
              <div className="blog-heading text-start py-2 mb-4"> Poster List </div>
             
              {poster?.map((blog) => (
                <PosterSection
                  key={blog.id}
                  user={user}
                  handleDelete={handleDelete}
                  {...blog}
                />
              ))}
  
              {!hide && (
                <button className="btn btn-primary" onClick={fetchMore}>
                  Load More
                </button>
              )}
            </div>
            
          </div>
        </div>
      </div>
    );
  };
  
  export default HomePoster;
  