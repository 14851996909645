import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage";
import { getAuth } from "firebase/auth";


const firebaseConfig = {
  apiKey: "AIzaSyBjXTxcE90hsxYW_4xiJ_YdkTCvERjTPoM",
  authDomain: "kraabtech-admin.firebaseapp.com",
  databaseURL: "https://kraabtech-admin-default-rtdb.firebaseio.com",
  projectId: "kraabtech-admin",
  storageBucket: "kraabtech-admin.appspot.com",
  messagingSenderId: "702249235945",
  appId: "1:702249235945:web:a9b981d11f416a05544778",
  measurementId: "G-DXXHJQPTF4"
};


const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const db = getFirestore(app);
const storage = getStorage(app);

export { auth, db, storage };